import React from "react";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "./dialog";
import { Divider } from "./divider"; // Import the Divider component

const InboxModal = ({ isOpen, onClose, currentWeek, usersWithoutPicks }) => {
  // Remove the useState and useEffect hooks for fetching users without picks

  return (
    <Dialog open={isOpen} onClose={onClose} size="sm">
      <div className="flex flex-col h-[400px] sm:h-[500px]">
        <DialogTitle className="flex-shrink-0 p-3 border-b border-gray-200">
          No Picks: Week {currentWeek}
        </DialogTitle>
        <DialogBody className="flex-grow overflow-y-auto p-3">
          {usersWithoutPicks.length > 0 ? (
            <ul className="space-y-2">
              {usersWithoutPicks.map((username, index) => (
                <React.Fragment key={username}>
                  <li className="px-3 py-2 text-sm text-slate-100">
                    {username}
                  </li>
                  {index < usersWithoutPicks.length - 1 && (
                    <Divider soft className="my-2" />
                  )}
                </React.Fragment>
              ))}
            </ul>
          ) : (
            <p className="text-sm text-slate-100">
              All users have submitted their picks for this week.
            </p>
          )}
        </DialogBody>
        <DialogActions className="flex-shrink-0 p-3 border-t border-gray-200">
          <button
            onClick={onClose}
            className="w-full bg-blue-500 text-white px-4 py-2 text-sm rounded hover:bg-blue-600"
          >
            Close
          </button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default InboxModal;
