import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Bell, User, LogOut, Settings } from "lucide-react";
import { DevicePhoneMobileIcon } from "@heroicons/react/24/outline";
import AddToHome from "./AddToHome";
import { getCurrentWeek } from "./weekDates";
import InboxModal from "./InboxModal";
import { supabase } from "../supabaseClient";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

const USERNAMES = [
  "weedenmh",
  "Weeds11",
  "Bleet",
  "JeremyBinding",
  "bthomas1090",
  "anthonyq15",
  "dharitos72",
  "daschdegger",
  "remmij",
  "pblang",
  "Jzebzda",
  "marvizu",
  "iengsj",
  "TOB",
  "camgodinsky",
  "lstokes10",
  "ttardiff20",
];

const Header = ({ userName, onLogout, setUserName }) => {
  const [isAddToHomeOpen, setIsAddToHomeOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);

  const [hasNotification, setHasNotification] = useState(false);
  const [usersWithoutPicks, setUsersWithoutPicks] = useState([]);
  const navigate = useNavigate();
  const currentWeek = getCurrentWeek();

  const fetchUsersWithoutPicks = useCallback(async () => {
    try {
      const { data: usersWithPicks, error: picksError } = await supabase
        .from("user_picks")
        .select("username")
        .eq("week", currentWeek);

      if (picksError) throw picksError;

      const usernamesWithPicks = new Set(
        usersWithPicks.map((user) => user.username.toLowerCase())
      );

      const usersWithoutPicks = USERNAMES.filter(
        (username) => !usernamesWithPicks.has(username.toLowerCase())
      );

      setUsersWithoutPicks(usersWithoutPicks);
      setHasNotification(usersWithoutPicks.length > 0);
    } catch (error) {
      console.error("Error fetching users without picks:", error);
    }
  }, [currentWeek]);

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      setIsMobile(
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent.toLowerCase()
        )
      );
    };

    const checkIfStandalone = () => {
      setIsStandalone(window.matchMedia("(display-mode: standalone)").matches);
    };

    checkIfMobile();
    checkIfStandalone();
    window.addEventListener("resize", checkIfMobile);
    window.addEventListener("resize", checkIfStandalone);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
      window.removeEventListener("resize", checkIfStandalone);
    };
  }, []);

  useEffect(() => {
    if (userName === "pblang") {
      fetchUsersWithoutPicks();
    }
  }, [userName, fetchUsersWithoutPicks]);

  const openAddToHome = () => {
    setIsAddToHomeOpen(true);
  };

  const closeAddToHome = () => {
    setIsAddToHomeOpen(false);
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleAccountSettingsClick = () => {
    navigate("/account-settings");
  };

  const openInbox = () => {
    setIsInboxOpen(true);
  };

  const closeInbox = () => {
    setIsInboxOpen(false);
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-gray-800 text-white shadow-md z-50">
      <div className="container mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold font-heading flex items-center">
            <img
              src="/logo512.png"
              alt="Logo"
              className="h-10 w-10 mr-3 rounded-full cursor-pointer"
              onClick={() => navigate("/")}
            />
          </h1>
          <div className="flex items-center space-x-4">
            {userName === "pblang" && (
              <button
                onClick={openInbox}
                className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 relative"
                aria-label="Inbox"
              >
                <Bell className="h-5 w-5" />
                {hasNotification && (
                  <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />
                )}
              </button>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button className="flex items-center space-x-2 bg-gray-700 hover:bg-gray-600 text-sm rounded-full p-1.5 pr-3 focus:outline-none focus:ring-0">
                  <Avatar className="h-8 w-8 rounded-full bg-gray-500">
                    <AvatarImage
                      src="/avatars/01.png"
                      alt={userName}
                      className="rounded-full"
                    />
                    <AvatarFallback className="text-white">
                      {userName.charAt(0).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  <span className="inline-block">{userName}</span>
                </button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="mt-2 w-56 bg-gray-800 rounded-md shadow-lg py-1 text-white"
                align="end"
              >
                <DropdownMenuLabel className="px-4 py-2 text-sm font-medium">
                  {userName}
                </DropdownMenuLabel>
                <DropdownMenuSeparator className="my-1 border-t border-gray-700" />
                <DropdownMenuItem
                  onClick={handleProfileClick}
                  className="px-4 py-2 text-sm hover:bg-gray-700 flex items-center rounded-md mx-1"
                >
                  <User className="mr-2 h-4 w-4" />
                  <span>Profile</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={handleAccountSettingsClick}
                  className="px-4 py-2 text-sm hover:bg-gray-700 flex items-center rounded-md mx-1"
                >
                  <Settings className="mr-2 h-4 w-4" />
                  <span>Settings</span>
                </DropdownMenuItem>
                {isMobile && !isStandalone && (
                  <DropdownMenuItem
                    onClick={openAddToHome}
                    className="px-4 py-2 text-sm hover:bg-gray-700 flex items-center rounded-md mx-1"
                  >
                    <DevicePhoneMobileIcon className="mr-2 h-4 w-4" />
                    <span>Get the app</span>
                  </DropdownMenuItem>
                )}
                <DropdownMenuSeparator className="my-1 border-t border-gray-700" />
                <DropdownMenuItem
                  onClick={onLogout}
                  className="px-4 py-2 text-sm hover:bg-gray-700 flex items-center rounded-md mx-1"
                >
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <AddToHome isOpen={isAddToHomeOpen} onClose={closeAddToHome} />
      {userName === "pblang" && (
        <InboxModal
          isOpen={isInboxOpen}
          onClose={closeInbox}
          currentWeek={currentWeek}
          usersWithoutPicks={usersWithoutPicks}
        />
      )}
    </header>
  );
};

export default Header;
