import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  ChevronDown,
  ChevronUp,
  Loader2,
  Lock,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import weekDates, {
  isWeekAvailable,
  getLatestAvailableWeek,
  getCurrentWeek,
} from "./weekDates";
import { supabase } from "../supabaseClient";
import {
  getGamesForWeek,
  isPickCorrect,
  hasGameStarted,
  updateScores,
  hasGameFinished,
} from "../gameData";

const LeaderboardPage = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(getLatestAvailableWeek());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [picks, setPicks] = useState({});
  const [weeklyCorrectPicks, setWeeklyCorrectPicks] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const currentWeekRef = useRef(null);
  const selectedWeekRef = useRef(selectedWeek);
  const [currentWeek, setCurrentWeek] = useState(getLatestAvailableWeek());

  useEffect(() => {
    selectedWeekRef.current = selectedWeek;
  }, [selectedWeek]);

  const fetchWeeklyWinData = useCallback(async (week) => {
    if (!isWeekAvailable(week)) {
      return [];
    }
    try {
      const { data, error } = await supabase
        .from("user_picks")
        .select("username")
        .eq("week", week)
        .order("tiebreaker", { ascending: false });

      if (error) throw error;

      return data.map((row) => row.username);
    } catch (error) {
      console.error("Error fetching weekly win data:", error);
      return [];
    }
  }, []);

  const fetchPicksData = useCallback(async (week) => {
    try {
      const { data, error } = await supabase
        .from("user_picks")
        .select("username, picks, tiebreaker")
        .eq("week", week);

      if (error) throw error;

      const picksData = data.reduce((acc, row) => {
        acc[row.username] = {
          username: row.username,
          picks: row.picks,
          tiebreaker: row.tiebreaker,
        };
        return acc;
      }, {});

      return picksData;
    } catch (error) {
      console.error("Error fetching picks data:", error);
      return {};
    }
  }, []);

  const calculateRanks = useCallback((leaderboardData) => {
    let currentRank = 1;
    let previousScore = null;
    let rankIncrement = 0;

    return leaderboardData.map((row, index) => {
      const [username, totalCorrectPicks] = row;
      if (totalCorrectPicks !== previousScore) {
        currentRank += rankIncrement;
        rankIncrement = 1;
      } else {
        rankIncrement++;
      }
      previousScore = totalCorrectPicks;
      return [username, totalCorrectPicks, currentRank];
    });
  }, []);

  const fetchLeaderboardData = useCallback(async () => {
    setIsLoading(true);
    const week = selectedWeekRef.current;

    const cachedData = sessionStorage.getItem(`leaderboardData_week_${week}`);
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      setLeaderboard(parsedData.leaderboard);
      setPicks(parsedData.picks);
      setWeeklyCorrectPicks(parsedData.weeklyCorrectPicks);
      setIsLoading(false);
      return;
    }

    try {
      // Update scores for all weeks up to the current week
      for (let w = 1; w <= week; w++) {
        await updateScores(w);
      }

      // Fetch all user picks across all weeks
      const { data: allUserPicks, error: allUserPicksError } = await supabase
        .from("user_picks")
        .select("username, week, picks");

      if (allUserPicksError) throw allUserPicksError;

      // Calculate total correct picks and weekly correct picks for each user
      const userCorrectPicks = {};
      const userWeeklyCorrectPicks = {};

      allUserPicks.forEach(({ username, week, picks }) => {
        if (!userCorrectPicks[username]) {
          userCorrectPicks[username] = 0;
        }
        if (!userWeeklyCorrectPicks[username]) {
          userWeeklyCorrectPicks[username] = {};
        }

        const weekGames = getGamesForWeek(week);
        let userPicks = typeof picks === "object" ? picks : JSON.parse(picks);

        userWeeklyCorrectPicks[username][week] = 0;
        Object.entries(userPicks).forEach(([gameIndex, pick]) => {
          const gameResult = weekGames[gameIndex];
          if (!gameResult) {
            console.warn(
              `No game result for week ${week}, game index ${gameIndex}`
            );
            return;
          }
          if (hasGameFinished(gameResult) && isPickCorrect(pick, gameResult)) {
            userWeeklyCorrectPicks[username][week] += 1;
            userCorrectPicks[username] += 1; // Increment total correct picks
          }
        });
      });

      const leaderboardData = Object.entries(userCorrectPicks).map(
        ([username, totalCorrectPicks]) => [username, totalCorrectPicks]
      );

      // Sort the leaderboard data by total correct picks in descending order
      leaderboardData.sort((a, b) => b[1] - a[1]);

      // Calculate ranks with ties
      const rankedLeaderboard = calculateRanks(leaderboardData);

      const weeklyWinsData = await fetchWeeklyWinData(week);
      const picksData = await fetchPicksData(week);

      setLeaderboard(rankedLeaderboard);
      setPicks(picksData);
      setWeeklyCorrectPicks(userWeeklyCorrectPicks);

      const fetchedData = {
        leaderboard: rankedLeaderboard,
        weeklyWins: weeklyWinsData,
        picks: picksData,
        weeklyCorrectPicks: userWeeklyCorrectPicks,
      };

      sessionStorage.setItem(
        `leaderboardData_week_${week}`,
        JSON.stringify(fetchedData)
      );

      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError("Failed to fetch data. Please try again later.");
      setIsLoading(false);
    }
  }, [fetchWeeklyWinData, fetchPicksData, calculateRanks]);

  useEffect(() => {
    fetchLeaderboardData();
  }, [fetchLeaderboardData, selectedWeek]);

  const handleRowClick = useCallback((username) => {
    setExpandedRows((prevRows) => {
      if (prevRows.includes(username)) {
        return prevRows.filter((user) => user !== username);
      } else {
        return [...prevRows, username];
      }
    });
  }, []);

  useEffect(() => {}, [expandedRows]);

  const handleWeekChange = (newWeek) => {
    if (isWeekAvailable(newWeek)) {
      setSelectedWeek(newWeek);
      setExpandedRows([]);
      setPicks({});
      fetchLeaderboardData();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderPicks = useCallback(
    (picksData) => {
      if (!picksData || !picksData.picks) {
        return null;
      }

      const picks = Object.entries(picksData.picks);
      const tiebreaker = picksData.tiebreaker;
      const weekGames = getGamesForWeek(selectedWeek);

      return (
        <div className="bg-gray-800 p-3 sm:p-4 rounded-lg shadow-lg max-w-4xl mx-auto">
          <h3 className="text-lg sm:text-xl font-bold text-blue-300 mb-3 sm:mb-4 text-center">
            Week {selectedWeek} Picks for {picksData.username}
          </h3>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-3">
            {picks.map(([index, pick], i) => {
              const gameData = weekGames[i];
              const gameFinished = hasGameFinished(gameData);
              const isCorrect = gameFinished && isPickCorrect(pick, gameData);
              const isPush = gameFinished && gameData.winner === "PUSH";
              const isNull = !gameData.winner;
              const gameStarted = hasGameStarted(gameData.startTime);

              if (!gameStarted) {
                return (
                  <div
                    key={i}
                    className="bg-gray-800 rounded-lg p-2 sm:p-3 relative border-2 border-gray-500 shadow-md"
                  >
                    <div className="flex justify-center items-center h-full">
                      <span className="text-sm sm:text-base font-medium text-center text-gray-400">
                        Game not started
                      </span>
                    </div>
                    <div className="absolute right-2 top-1/2 transform -translate-y-1/2">
                      <Lock className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" />
                    </div>
                  </div>
                );
              }

              let [teamAbbr, spread] = pick.split(" ");
              // Remove parentheses from spread if they exist
              spread = spread.replace(/[()]/g, "");
              const team =
                gameData.away.abbreviation === teamAbbr
                  ? gameData.away
                  : gameData.home;

              return (
                <div
                  key={i}
                  className={`bg-gray-750 rounded-lg p-2 sm:p-3 relative ${
                    isNull || !gameFinished
                      ? "border-gray-500"
                      : isPush
                      ? "border-yellow-500"
                      : isCorrect
                      ? "border-green-500"
                      : "border-red-500"
                  } border-2 shadow-md`}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <img
                        src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${teamAbbr.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=50&h=50`}
                        alt={team.name}
                        className="w-8 h-8 sm:w-10 sm:h-10"
                      />
                      <span className="text-sm sm:text-base font-medium text-gray-200">
                        {team.name}
                      </span>
                    </div>
                    <span className="text-sm sm:text-base font-semibold text-gray-300">
                      {spread}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 sm:mt-5 bg-blue-800 rounded-lg p-3 sm:p-4 shadow-lg">
            <div className="flex justify-between items-center">
              <span className="text-base sm:text-lg font-semibold text-blue-200">
                Tiebreaker
              </span>
              <span className="text-xl sm:text-2xl font-bold text-blue-100">
                {tiebreaker}
              </span>
            </div>
          </div>
        </div>
      );
    },
    [selectedWeek]
  );

  useEffect(() => {
    const updateCurrentWeek = () => {
      const newCurrentWeek = getCurrentWeek();
      if (newCurrentWeek !== currentWeek) {
        setCurrentWeek(newCurrentWeek);
        setSelectedWeek(newCurrentWeek);
      }
    };

    updateCurrentWeek(); // Initial check
    const interval = setInterval(updateCurrentWeek, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [currentWeek]);

  useEffect(() => {
    if (isDropdownOpen && currentWeekRef.current) {
      currentWeekRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [isDropdownOpen]);

  const WeekSelector = () => {
    const weeks = Object.keys(weekDates).map(Number);
    const currentWeekIndex = weeks.indexOf(selectedWeek);

    let visibleWeeks = [];
    if (currentWeekIndex === 0) {
      visibleWeeks = weeks.slice(0, 3);
    } else if (currentWeekIndex === weeks.length - 1) {
      visibleWeeks = weeks.slice(-3);
    } else {
      visibleWeeks = [
        weeks[currentWeekIndex - 1],
        weeks[currentWeekIndex],
        weeks[currentWeekIndex + 1],
      ];
    }

    return (
      <div className="flex items-center justify-center space-x-2 mb-6">
        <button
          onClick={() => handleWeekChange(selectedWeek - 1)}
          disabled={!isWeekAvailable(selectedWeek - 1)}
          className="p-1 rounded-full hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronLeft className="w-6 h-6 text-gray-300" />
        </button>
        {visibleWeeks.map((week) => (
          <button
            key={week}
            onClick={() => handleWeekChange(week)}
            disabled={!isWeekAvailable(week)}
            className={`px-3 py-1 rounded-md text-sm font-medium ${
              week === selectedWeek
                ? "bg-blue-600 text-white"
                : "text-gray-300 hover:bg-gray-700"
            } ${!isWeekAvailable(week) ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            WEEK {week}
          </button>
        ))}
        <button
          onClick={() => handleWeekChange(selectedWeek + 1)}
          disabled={!isWeekAvailable(selectedWeek + 1)}
          className="p-1 rounded-full hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronRight className="w-6 h-6 text-gray-300" />
        </button>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <Loader2 className="h-16 w-16 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="bg-red-900 bg-opacity-50 border border-red-700 rounded-lg p-6">
          <h2 className="text-2xl font-bold text-red-300 mb-3">Error</h2>
          <p className="text-lg text-red-100">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="text-gray-100 bg-gray-900 mb-6">
      <div>
        <div>
          <div className="container mx-auto px-4 py-8 max-w-4xl relative z-0">
            <WeekSelector />
          </div>

          <div className="bg-gray-800 rounded-lg overflow-hidden shadow-2xl">
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="bg-gray-750">
                  <tr>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Rank
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Username
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Total
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Week
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider w-8"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {leaderboard.map((row, index) => (
                    <React.Fragment key={index}>
                      <tr
                        className={`transition-colors cursor-pointer hover:bg-gray-750 ${
                          expandedRows.includes(row[0]) ? "bg-gray-700" : ""
                        }`}
                        onClick={() => handleRowClick(row[0])}
                      >
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center">
                          <span className="inline-flex items-center justify-center w-6 h-6 sm:w-7 sm:h-7 text-xs sm:text-sm leading-5 font-semibold rounded-full bg-blue-900 text-blue-200">
                            {row[2]} {/* Use the calculated rank */}
                          </span>
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-left">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-sm sm:text-base">
                              {row[0]}
                            </span>
                          </div>
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center text-sm sm:text-base font-semibold">
                          {row[1]}
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center text-sm sm:text-base font-semibold">
                          {weeklyCorrectPicks[row[0]][selectedWeek] || 0}
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center">
                          {expandedRows.includes(row[0]) ? (
                            <ChevronUp
                              className="text-blue-400 inline-block"
                              size={18}
                            />
                          ) : (
                            <ChevronDown
                              className="text-blue-400 inline-block"
                              size={18}
                            />
                          )}
                        </td>
                      </tr>
                      {expandedRows.includes(row[0]) && (
                        <tr>
                          <td
                            colSpan={5}
                            className="px-2 py-4 sm:py-6 bg-gray-800"
                          >
                            {picks[row[0]] ? (
                              renderPicks(picks[row[0]])
                            ) : (
                              <div className="text-center text-red-400">
                                No picks data available for {row[0]}
                              </div>
                            )}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardPage;
