import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Add this import
import { supabase } from "../supabaseClient";
import Modal from "./Modal";
import SignupModal from "./SignupModal";
import { Button } from "./button";
import { User, Lock } from "lucide-react";

const LoginComponent = () => {
  const navigate = useNavigate(); // Add this line
  const [identifier, setIdentifier] = useState(""); // This will be either username or email
  const [password, setPassword] = useState("");
  const [loggedInUsername, setLoggedInUsername] = useState("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [modalContent, setModalContent] = useState({ title: "", message: "" });
  const [isSignupVisible, setIsSignupVisible] = useState(true);

  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    // Re-enable scrolling on unmount
    return () => {
      document.body.style.overflow = "unset";
      document.documentElement.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    // Set the cutoff date to September 5th, 2024 at 17:00 (5:00 PM)
    const cutoffDate = new Date("2024-09-09T20:20:00");

    if (currentDate >= cutoffDate) {
      setIsSignupVisible(false);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let email = identifier;
      // Check if identifier is not an email, treat it as a username
      if (!identifier.includes("@")) {
        email = `${identifier}@pempool-123-test-1.com`;
      }

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      if (data.user) {
        const { data: profileData, error: profileError } = await supabase
          .from("profiles")
          .select("username")
          .eq("id", data.user.id)
          .single();

        if (profileError) throw profileError;

        if (profileData && profileData.username) {
          console.log(`Logged in as: ${profileData.username}`);
          setLoggedInUsername(profileData.username);
          navigate("/scores"); // Add this line to redirect to ScoresPage
        }
      }

      localStorage.setItem("supabase.auth.token", JSON.stringify(data.session));
    } catch (error) {
      setModalContent({
        title: "Login Error",
        message: error.message,
      });
      setIsLoginModalOpen(true);
    }
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const openSignupModal = () => {
    setIsSignupModalOpen(true);
  };

  const closeSignupModal = () => {
    setIsSignupModalOpen(false);
  };

  const openForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(true);
  };

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
  };

  const handleEmailSupport = () => {
    window.location.href =
      "mailto:blangpab@gmail.com?subject=Password Reset Request";
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-gray-900 to-gray-800 overflow-hidden">
      <div className="w-full max-w-md p-6 flex flex-col justify-center">
        <div className="mb-8 text-center">
          <img
            src="/logo512.png"
            alt="Logo"
            className="mx-auto h-20 w-20 mb-4 rounded-xl"
          />
          <h2 className="text-3xl font-extrabold text-white">
            Sign in to NFL Pick'em
          </h2>
        </div>
        <form
          className="bg-gray-800 shadow-2xl rounded-lg px-8 pt-6 pb-8 mb-4"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label
              htmlFor="identifier"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Username
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="identifier"
                name="identifier"
                type="text"
                required
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 bg-gray-700 text-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your username"
                autoCapitalize="none"
                autoCorrect="off"
                autoComplete="username"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Password
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="password"
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none block w-full pl-10 pr-3 py-2 border border-gray-600 rounded-md shadow-sm placeholder-gray-400 bg-gray-700 text-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter your password"
                autoComplete="current-password"
              />
            </div>
          </div>
          <div className="flex items-center justify-between mb-6">
            <button
              type="button"
              onClick={openForgotPasswordModal}
              className="text-sm font-medium text-indigo-400 hover:text-indigo-300"
            >
              Forgot password?
            </button>
          </div>
          <div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign in
            </button>
          </div>
        </form>
        {isSignupVisible && (
          <div className="text-center">
            <button
              onClick={openSignupModal}
              className="text-sm font-medium text-indigo-300 hover:text-indigo-200"
            >
              Want to join the pool? Sign up
            </button>
          </div>
        )}
      </div>

      <Modal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        title={modalContent.title}
        type="error"
      >
        <p>{modalContent.message}</p>
      </Modal>

      <SignupModal isOpen={isSignupModalOpen} onClose={closeSignupModal} />

      <Modal
        isOpen={isForgotPasswordModalOpen}
        onClose={closeForgotPasswordModal}
        title="Forgot Password"
        type="info"
      >
        <div className="text-center">
          <p className="mb-4 text-gray-300">
            Please reach out to support to change your password.
          </p>
          <Button color="blue" onClick={handleEmailSupport} className="w-50">
            Email Support
          </Button>
        </div>
      </Modal>

      {loggedInUsername && (
        <div className="text-center text-white mt-4">
          Logged in as: {loggedInUsername}
        </div>
      )}
    </div>
  );
};

export default LoginComponent;
