import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { toast } from "react-toastify";
import { Eye, EyeOff } from "lucide-react";

const AccountSettingsPage = ({ userId, userName }) => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [authEmail, setAuthEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        // Fetch profile data
        const { data: profileData, error: profileError } = await supabase
          .from("profiles")
          .select("name, username, venmo, email")
          .eq("id", userId)
          .single();

        if (profileError) throw profileError;

        // Fetch auth user data
        const { data: authData, error: authError } =
          await supabase.auth.getUser();

        if (authError) throw authError;

        setUserInfo(profileData);
        setAuthEmail(authData.user.email);
      } catch (error) {
        console.error("Error fetching user data:", error);
        toast.error("Failed to load user information");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsEditing(true);
    setLoading(true);

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match");
      setLoading(false);
      setIsEditing(false);
      return;
    }

    try {
      // Verify the current password using the auth email
      const { data, error: signInError } =
        await supabase.auth.signInWithPassword({
          email: authEmail,
          password: currentPassword,
        });

      if (signInError || !data.user) {
        toast.error("Current password is incorrect");
        setLoading(false);
        setIsEditing(false);
        return;
      }

      // If current password is correct, update to the new password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) {
        toast.error("Error updating password: " + updateError.message);
      } else {
        toast.success("Password updated successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      console.error("Error during password change:", error);
      toast.error("An error occurred while changing the password");
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <h1 className="text-3xl font-bold mb-8 text-center text-white">
        Account Settings
      </h1>

      <div className="bg-gray-800 p-8 rounded-lg shadow-lg mb-8">
        <h2 className="text-2xl font-semibold mb-6 text-white">
          Account Information
        </h2>
        {loading ? (
          <p className="text-gray-300">Loading user information...</p>
        ) : (
          <div className="space-y-4">
            {Object.entries(userInfo).map(([key, value]) => (
              <div key={key} className="flex flex-col">
                <label className="text-sm font-medium text-gray-400 mb-1">
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <div className="w-full px-3 py-2 bg-gray-700 text-white rounded-md">
                  {value || ""}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-6 text-white">
          Change Password
        </h2>
        <form onSubmit={handlePasswordChange} className="space-y-6">
          <div>
            <label
              className="block text-sm font-medium text-gray-400 mb-1"
              htmlFor="currentPassword"
            >
              Current Password
            </label>
            <div className="relative">
              <input
                type={showCurrentPassword ? "text" : "password"}
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={toggleCurrentPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-white"
              >
                {showCurrentPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-400 mb-1"
              htmlFor="newPassword"
            >
              New Password
            </label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={toggleNewPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-white"
              >
                {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </button>
            </div>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-400 mb-1"
              htmlFor="confirmPassword"
            >
              Confirm New Password
            </label>
            <input
              type={showNewPassword ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            disabled={loading || isEditing}
            className={`w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out ${
              (loading || isEditing) && "opacity-50 cursor-not-allowed"
            }`}
          >
            {loading
              ? "Updating..."
              : isEditing
              ? "Processing..."
              : "Change Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccountSettingsPage;
