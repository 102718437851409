import React from "react";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "./dialog";
import { Button } from "./button"; // Assuming you have a Button component

const AddToHome = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} size="md">
      <DialogTitle className="text-2xl font-bold text-gray-800 dark:text-white">
        Get the App
      </DialogTitle>
      <DialogBody>
        <div className="flex flex-col items-center">
          <img
            src="/logo192.png"
            alt="App Icon"
            className="w-16 h-16 mb-4 rounded-lg"
          />
          <p className="text-center mb-4 text-gray-600 dark:text-gray-300">
            Add our app to your home screen for quick and easy access.
          </p>
          <div className="flex flex-col items-center mb-4">
            <p className="text-center mb-2 text-gray-600 dark:text-gray-300">
              <strong>Step 1:</strong> Tap the share button below.
            </p>
            <IosShareIcon
              className="text-blue-500 mb-2"
              style={{ fontSize: "2em" }}
            />
            <p className="text-center mb-2 mt-4 text-gray-600 dark:text-gray-300">
              <strong>Step 2:</strong> Select{" "}
              <strong>Add to Home Screen</strong>.
            </p>
          </div>
          <p className="text-center mb-4 text-gray-600 dark:text-gray-300">
            No download required. The website will function like a standalone
            app.
          </p>
        </div>
      </DialogBody>
      <DialogActions>
        <Button
          onClick={onClose}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          color="blue"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToHome;
