import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { supabase } from "./supabaseClient";
import LoginComponent from "./components/LoginComponent";
import HomePage from "./components/HomePage";
import LeaderboardPage from "./components/LeaderboardPage";
import ProfilePage from "./components/ProfilePage";
import ScoresPage from "./components/ScoresPage"; // Add this import
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Layout";
import AccountSettingsPage from "./components/AccountSettingsPage";

const REFRESH_THRESHOLD = 20 * 60 * 1000; // 20 minutes in milliseconds

function App() {
  const [session, setSession] = useState(null);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [isScrollable, setIsScrollable] = useState(true); // New state variable
  const [hideBottomNav, setHideBottomNav] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data: sessionData, error } = await supabase.auth.getSession();
        if (error) throw error;
        setSession(sessionData.session);
        if (sessionData.session && sessionData.session.user) {
          const fetchedUsername = sessionData.session.user.email.split("@")[0];
          setUsername(fetchedUsername);
        }
      } catch (error) {
        console.error("Error fetching session:", error.message);
        setSession(null);
      } finally {
        setLoading(false);
      }
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (_event, sessionData) => {
        setSession(sessionData);
        if (sessionData && sessionData.user) {
          const newUsername = sessionData.user.email.split("@")[0];
          setUsername(newUsername);
        } else {
          setUsername("");
        }
      }
    );

    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const initializeTimes = () => {
      const startTime =
        localStorage.getItem("startTime") || Date.now().toString();
      localStorage.setItem("startTime", startTime);
      localStorage.setItem("lastVisit", Date.now().toString());
    };

    initializeTimes();

    const updateLastVisit = () => {
      localStorage.setItem("lastVisit", Date.now().toString());
    };

    const checkRefresh = () => {
      const currentTime = Date.now();
      const startTime = parseInt(localStorage.getItem("startTime"), 10);
      const elapsedTime = currentTime - startTime;
      if (elapsedTime >= REFRESH_THRESHOLD) {
        refreshData();
        localStorage.setItem("startTime", currentTime.toString());
      }
      updateLastVisit();
    };

    checkRefresh();

    const interval = setInterval(checkRefresh, 1000);

    return () => clearInterval(interval);
  }, []);

  const refreshData = () => {
    // Your data refresh logic here
    console.log("Data refreshed!");
    // Refresh the page to ensure all data is up-to-date
    window.location.reload();
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
    setUsername("");
  };

  useEffect(() => {
    // Add or remove the 'no-scroll' class based on isScrollable state
    if (isScrollable) {
      document.body.classList.remove("no-scroll");
    } else {
      document.body.classList.add("no-scroll");
    }
  }, [isScrollable]);

  if (loading) {
    return <div></div>; // Or a more sophisticated loading component
  }

  return (
    <Router>
      <ScrollToTop />
      {!session ? (
        <LoginComponent />
      ) : (
        <div className="App min-h-screen flex flex-col bg-gray-900">
          <Layout
            username={username}
            onLogout={handleLogout}
            hideBottomNav={hideBottomNav}
            setUserName={setUsername}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/scores" replace />} />
              <Route path="/scores" element={<ScoresPage />} />
              <Route
                path="/home"
                element={
                  <HomePage
                    username={username}
                    setIsScrollable={setIsScrollable}
                    setHideBottomNav={setHideBottomNav}
                  />
                }
              />
              <Route path="/leaderboard" element={<LeaderboardPage />} />
              <Route
                path="/profile"
                element={
                  <ProfilePage userId={session.user.id} userName={username} />
                }
              />
              <Route
                path="/account-settings"
                element={
                  <AccountSettingsPage
                    userId={session.user.id}
                    userName={username}
                    setUserName={setUsername}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/scores" replace />} />
            </Routes>
          </Layout>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            draggable
            theme="dark"
            limit={3}
          />
        </div>
      )}
    </Router>
  );
}

export default App;
