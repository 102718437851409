import * as Headless from "@headlessui/react";
import clsx from "clsx";

import { Text } from "./text";

const sizes = {
  xs: "sm:max-w-xs",
  sm: "sm:max-w-sm",
  md: "sm:max-w-md",
  lg: "sm:max-w-lg",
  xl: "sm:max-w-xl",
  "2xl": "sm:max-w-2xl",
  "3xl": "sm:max-w-3xl",
  "4xl": "sm:max-w-4xl",
  "5xl": "sm:max-w-5xl",
};

export function Dialog({ size = "lg", className, children, ...props }) {
  return (
    <Headless.Dialog {...props}>
      <Headless.DialogBackdrop className="fixed inset-0 flex w-screen justify-center overflow-y-auto bg-gray-900/75 px-2 py-2 transition duration-100 focus:outline-0 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in sm:px-6 sm:py-8 lg:px-8 lg:py-16 backdrop-blur-sm" />

      <div className="fixed inset-0 w-screen overflow-y-auto pt-6 sm:pt-0">
        <div className="flex min-h-full items-center justify-center p-4">
          <Headless.DialogPanel
            className={clsx(
              className,
              sizes[size],
              "w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-gray-800",
              "sm:my-8 sm:w-full sm:max-w-lg"
            )}
          >
            {children}
          </Headless.DialogPanel>
        </div>
      </div>
    </Headless.Dialog>
  );
}

export function DialogTitle({ className, ...props }) {
  return (
    <Headless.DialogTitle
      {...props}
      className={clsx(
        className,
        "text-lg font-medium leading-6 text-gray-900 dark:text-white"
      )}
    />
  );
}

export function DialogDescription({ className, ...props }) {
  return (
    <Headless.Description
      as={Text}
      {...props}
      className={clsx(
        className,
        "mt-2 text-sm text-gray-500 dark:text-gray-300"
      )}
    />
  );
}

export function DialogBody({ className, ...props }) {
  return <div {...props} className={clsx(className, "mt-3")} />;
}

export function DialogActions({ className, ...props }) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        "mt-4 flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2"
      )}
    />
  );
}
