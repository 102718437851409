import React, { useState, useEffect, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CheckCircle, XCircle, AlertTriangle } from "lucide-react";
import Modal from "./Modal";
import { Loader2, Edit, ChevronRight } from "lucide-react";
import { supabase } from "../supabaseClient";
import {
  currentWeek,
  getGamesForWeek,
  hasGameStarted,
  getWinnersForWeek,
  isPickCorrect,
  updateScores,
} from "../gameData";
import { isWeekViewable, weekDates } from "./weekDates";
import { PicksLoader } from "./PicksLoader";
import styled, { keyframes } from "styled-components";
import { Button } from "./button";

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const LiveText = styled.span`
  font-weight: 600;
  color: #ef4444; // Tailwind's red-500
  transition: all 0.2s ease;
`;

const LiveDot = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ef4444; // Tailwind's red-500
  margin-right: 6px;
  animation: ${pulse} 2s ease-in-out infinite;
`;

const LiveContainer = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  background-color: rgba(
    239,
    68,
    68,
    0.1
  ); // Tailwind's red-500 with low opacity
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(239, 68, 68, 0.2);
    ${LiveText} {
      color: #dc2626; // Tailwind's red-600
    }
  }
`;

const isWeekInSubmissionPeriod = (weekNumber) => {
  const now = new Date();
  const weekDate = weekDates[weekNumber];

  return (
    weekDate &&
    now >= new Date(weekDate.availableFrom) &&
    now < new Date(weekDate.viewableFrom)
  );
};

const HomePage = ({ setHideBottomNav }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedPicks, setSelectedPicks] = useState({});
  const [originalPicks, setOriginalPicks] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [isFirstFetch, setIsFirstFetch] = useState(true);
  const [hasSubmittedPicks, setHasSubmittedPicks] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tiebreaker, setTiebreaker] = useState("");
  const [originalTiebreaker, setOriginalTiebreaker] = useState("");
  const teams = getGamesForWeek(currentWeek);
  const [gameScores, setGameScores] = useState({});
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalContent] = useState({
    title: "",
    message: "",
    type: "",
  });

  const getGameStatus = useCallback(
    (startTime, gameIndex) => {
      const now = new Date();
      const gameStart = new Date(startTime);
      const timeSinceStart = now - gameStart;
      const fourHoursInMs = 4 * 60 * 60 * 1000;

      if (hasGameStarted(startTime)) {
        if (gameScores[gameIndex]) {
          if (
            gameScores[gameIndex].clock === "0:00" &&
            gameScores[gameIndex].period >= 4
          ) {
            return "FINAL";
          } else {
            return `Q${gameScores[gameIndex].period} ${gameScores[gameIndex].clock}`;
          }
        } else if (timeSinceStart >= fourHoursInMs) {
          return "FINAL";
        } else {
          return "LIVE";
        }
      } else {
        return null;
      }
    },
    [gameScores]
  );

  const [editablePicks, setEditablePicks] = useState(() => {
    const initialEditablePicks = {};
    teams.forEach((game, index) => {
      initialEditablePicks[index] = !hasGameStarted(game.startTime);
    });
    return initialEditablePicks;
  });

  const clearSessionStorage = () => {
    sessionStorage.removeItem(`leaderboardData_week_${currentWeek}`);
    sessionStorage.removeItem(`profileData_${username}`);
  };

  const fetchUserData = useCallback(async () => {
    const cachedData = sessionStorage.getItem("homePageData");
    if (cachedData) {
      const data = JSON.parse(cachedData);
      if (
        data.week === currentWeek &&
        Date.now() - data.timestamp < 5 * 60 * 1000
      ) {
        // Use cached data if it's less than 5 minutes old
        setUsername(data.username);
        setHasSubmittedPicks(data.hasSubmittedPicks);
        setSelectedPicks(data.picks || {});
        setTiebreaker(data.tiebreaker || "");
        setIsLoading(false);
        setIsFirstFetch(false);
        setIsFormEditable(!data.hasSubmittedPicks);
        return;
      }
    }

    setIsLoading(true);
    try {
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError)
        throw new Error("Error getting user: " + userError.message);
      if (!user) throw new Error("No user logged in");

      const username = user.email.split("@")[0];
      setUsername(username);

      const { data: userPicks, error: picksError } = await supabase
        .from("user_picks")
        .select("*")
        .eq("user_id", user.id)
        .eq("week", currentWeek)
        .single();

      if (picksError && picksError.code !== "PGRST116") {
        throw new Error("Error fetching user picks: " + picksError.message);
      }

      const hasSubmitted = !!userPicks;
      setHasSubmittedPicks(hasSubmitted);
      if (hasSubmitted) {
        setSelectedPicks(userPicks.picks);
        setTiebreaker(userPicks.tiebreaker || "");
        setIsFormEditable(false);
      } else {
        setSelectedPicks({});
        setTiebreaker("");
        setIsFormEditable(true);
      }

      setIsEditing(false);
      setOriginalPicks({});
      setOriginalTiebreaker("");

      setIsLoading(false);
      setIsFirstFetch(false);

      const fetchedData = {
        username: username,
        hasSubmittedPicks: hasSubmitted,
        picks: hasSubmitted ? userPicks.picks : {},
        tiebreaker: hasSubmitted ? userPicks.tiebreaker : "",
        week: currentWeek,
        timestamp: Date.now(),
      };
      sessionStorage.setItem("homePageData", JSON.stringify(fetchedData));
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to load user data. Please try again.", {
        icon: <XCircle className="text-red-500" />,
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    const fetchScores = async () => {
      await updateScores(currentWeek);
      const games = getGamesForWeek(currentWeek);
      const scores = {};
      games.forEach((game, index) => {
        if (game.homeScore !== undefined && game.awayScore !== undefined) {
          scores[index] = {
            score: `${game.away.abbreviation} ${game.awayScore} - ${game.homeScore} ${game.home.abbreviation}`,
            clock: game.clock,
            period: game.period,
          };
        }
      });
      setGameScores(scores);
    };

    fetchScores();
    const interval = setInterval(fetchScores, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedEditablePicks = {};
      teams.forEach((game, index) => {
        const startTime = new Date(game.startTime);
        const now = new Date();
        const timeUntilStart = startTime - now;
        const oneHourInMs = 60 * 60 * 1000;

        const status = getGameStatus(game.startTime, index);
        if (status) {
          updatedEditablePicks[index] = false;
        } else if (timeUntilStart <= oneHourInMs && timeUntilStart > 0) {
          updatedEditablePicks[index] = true;
        } else {
          updatedEditablePicks[index] = true;
        }
      });
      setEditablePicks(updatedEditablePicks);
    }, 1000);

    return () => clearInterval(interval);
  }, [teams, getGameStatus]);

  const handlePickClick = (gameIndex, teamType) => {
    if (!isFormEditable || !editablePicks[gameIndex]) return;
    const team =
      teamType === "away" ? teams[gameIndex].away : teams[gameIndex].home;
    setSelectedPicks((prevPicks) => {
      const newPicks = {
        ...prevPicks,
        [gameIndex]: `${team.abbreviation} (${team.spread > 0 ? "+" : ""}${
          team.spread
        })`,
      };
      return newPicks;
    });
  };

  const sendPicksToSupabase = async (picks, week, tiebreaker) => {
    try {
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();

      if (userError)
        throw new Error("Error getting user: " + userError.message);
      if (!user) throw new Error("No user logged in");

      if (!username) {
        throw new Error("Unable to determine username");
      }

      const upsertData = {
        user_id: user.id,
        username: username,
        week: week,
        picks: picks,
        tiebreaker: tiebreaker,
      };

      const { error } = await supabase.from("user_picks").upsert(upsertData, {
        onConflict: "user_id,week",
        returning: "minimal",
      });

      if (error) {
        throw new Error(`Error upserting picks: ${error.message}`);
      }

      return true;
    } catch (error) {
      console.error("Error in sendPicksToSupabase:", error.message);
      throw error;
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const resetViewport = () => {
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    const picksToSubmit = { ...selectedPicks };
    let allGamesHavePicks = true;

    teams.forEach((game, index) => {
      if (!editablePicks[index] && !picksToSubmit[index]) {
        picksToSubmit[index] = "Not Submitted";
      } else if (!picksToSubmit[index]) {
        allGamesHavePicks = false;
      }
    });

    if (!allGamesHavePicks) {
      toast.warning(
        "Please make selections for all available games before submitting.",
        {
          icon: <AlertTriangle className="text-yellow-500" />,
        }
      );
      return;
    }

    setIsSubmitting(true);
    setIsUploading(true);
    try {
      await sendPicksToSupabase(picksToSubmit, currentWeek, tiebreaker);
      setHasSubmittedPicks(true);
      setIsEditing(false);
      setIsFormEditable(false);
      setSelectedPicks(picksToSubmit);
      toast.success(
        `Your picks for Week ${currentWeek} have been ${
          isEditing ? "updated" : "recorded"
        }. Good luck!`,
        {
          icon: <CheckCircle className="text-green-500" />,
        }
      );

      // Update session storage cache
      const updatedData = {
        username,
        hasSubmittedPicks: true,
        picks: picksToSubmit,
        tiebreaker,
        week: currentWeek,
        timestamp: Date.now(),
      };
      sessionStorage.setItem("homePageData", JSON.stringify(updatedData));

      resetViewport();
    } catch (error) {
      console.error("Detailed error:", error);
      toast.error(
        `Error ${
          isEditing ? "updating" : "submitting"
        } picks. Please try again.`,
        {
          icon: <XCircle className="text-red-500" />,
        }
      );
    } finally {
      setIsUploading(false);
      setIsSubmitting(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setIsFormEditable(true);
    setOriginalPicks({ ...selectedPicks });
    setOriginalTiebreaker(tiebreaker);
    clearSessionStorage();
    resetViewport();
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setIsFormEditable(false);
    setSelectedPicks(originalPicks);
    setTiebreaker(originalTiebreaker);
    resetViewport();
  };

  useEffect(() => {
    resetViewport();
  }, []);

  const [timeLeft, setTimeLeft] = useState(0);
  const [progress, setProgress] = useState(0);

  const weekInSubmissionPeriod = isWeekInSubmissionPeriod(currentWeek);
  const weekIsViewable = isWeekViewable(currentWeek);

  const nextWeekData = useMemo(() => {
    if (!weekInSubmissionPeriod && weekIsViewable) {
      const nextWeek = currentWeek + 1;
      const targetDate = new Date(weekDates[nextWeek].availableFrom);
      const startDate = new Date(weekDates[currentWeek].viewableFrom);
      return { nextWeek, targetDate, startDate };
    }
    return null;
  }, [weekInSubmissionPeriod, weekIsViewable]);

  useEffect(() => {
    if (nextWeekData) {
      const { targetDate, startDate } = nextWeekData;

      const updateTimeAndProgress = () => {
        const now = new Date();
        const difference = targetDate - now;
        const totalSeconds = Math.floor(difference / 1000);

        setTimeLeft(totalSeconds);

        // Calculate progress
        const totalDuration = targetDate - startDate;
        const elapsed = now - startDate;
        setProgress((elapsed / totalDuration) * 100);

        if (difference <= 0) {
          clearInterval(interval);
        }
      };

      // Run immediately to avoid delay
      updateTimeAndProgress();

      const interval = setInterval(updateTimeAndProgress, 1000);

      return () => clearInterval(interval);
    }
  }, [nextWeekData]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${days}d ${hours}h ${minutes}m`;
  };

  useEffect(() => {
    setHideBottomNav(isEditing);
    if (!isEditing) {
      resetViewport();
    }
  }, [isEditing, setHideBottomNav]);

  if (isLoading && isFirstFetch) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <Loader2 className="h-16 w-16 animate-spin text-blue-500" />
      </div>
    );
  }

  if (!weekInSubmissionPeriod && weekIsViewable && nextWeekData) {
    return (
      <div className="fixed inset-0 flex justify-center items-center bg-gray-900 p-4">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg max-w-md w-full mx-auto border border-gray-700">
          <h1 className="text-3xl font-bold mb-6 text-center">
            Week {nextWeekData.nextWeek}
          </h1>
          <div className="mb-8">
            <div className="w-full bg-gray-700 rounded-full h-2 mb-4 overflow-hidden">
              <div
                className="bg-blue-500 h-full rounded-full transition-all duration-500 ease-out"
                style={{ width: `${Math.min(progress, 100)}%` }}
              ></div>
            </div>
            <div className="flex items-center justify-center text-2xl font-semibold text-blue-400">
              {formatTime(timeLeft)}
            </div>
          </div>
          <p className="text-lg mb-6 text-center">
            We're preparing the matchups for the upcoming week.
          </p>
          <div className="flex flex-col items-center justify-center text-center text-gray-400 mb-8">
            <span>Available on:</span>
            <span>
              {nextWeekData.targetDate.toLocaleString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
                timeZone: "America/New_York",
              })}{" "}
              EST
            </span>
          </div>
        </div>
      </div>
    );
  }

  const getPickStatus = (gameIndex, pick) => {
    const winners = getWinnersForWeek(currentWeek);
    const gameResult = winners[gameIndex];

    if (!gameResult || gameResult.winner === "NULL") {
      return "pending";
    }

    if (gameResult.winner === "PUSH") {
      return "push";
    }

    return isPickCorrect(pick, gameResult) ? "correct" : "incorrect";
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <main
        className={`container mx-auto px-4 pt-10 pb-8 max-w-4xl ${
          isEditing ? "pb-32" : "pb-20"
        }`}
      >
        <div className="bg-gray-800 rounded-lg shadow-lg p-4 mb-6">
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <div className="text-center sm:text-left mb-4 sm:mb-0">
              <h2 className="text-2xl font-bold text-white">
                Week {currentWeek}
              </h2>
              <p className="text-sm text-blue-400 mt-1">
                {hasSubmittedPicks
                  ? isEditing
                    ? "Edit your picks"
                    : "Picks locked in"
                  : "Make your predictions"}
              </p>
            </div>
            <div className="flex items-center space-x-2">
              {!isEditing && !isFormEditable && (
                <Button
                  type="button"
                  onClick={handleEdit}
                  color="green"
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 rounded text-xs flex items-center"
                >
                  Edit
                  <Edit className="w-3 h-3 ml-1" />
                </Button>
              )}
            </div>
          </div>
        </div>

        {(isFormEditable || !hasSubmittedPicks) && (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {teams.map((game, index) => (
                <div
                  key={index}
                  className={`bg-gray-800 rounded-lg shadow-md overflow-hidden ${
                    !editablePicks[index] ? "opacity-50" : ""
                  }`}
                >
                  <div className="bg-gray-800 border-gray-600 px-4 py-2 border-b flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-gray-200 flex items-center">
                      {game.away.abbreviation} @ {game.home.abbreviation}
                    </h2>
                    <span className="text-sm text-gray-400">
                      {getGameStatus(game.startTime, index) === "LIVE" ? (
                        <LiveContainer>
                          <LiveDot />
                          <LiveText>
                            {gameScores[index]
                              ? `Q${gameScores[index].period} ${gameScores[index].clock}`
                              : "LIVE"}
                          </LiveText>
                        </LiveContainer>
                      ) : getGameStatus(game.startTime, index) &&
                        getGameStatus(game.startTime, index) !== "FINAL" ? (
                        <span className="text-yellow-400 font-bold">
                          {getGameStatus(game.startTime, index)}
                        </span>
                      ) : getGameStatus(game.startTime, index) !== "FINAL" ? (
                        new Date(game.startTime)
                          .toLocaleString("en-US", {
                            weekday: "short",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "2-digit",
                            timeZone: "America/New_York",
                            hour12: true,
                          })
                          .replace(",", "")
                          .toUpperCase() + " EST"
                      ) : (
                        "FINAL"
                      )}
                    </span>
                  </div>
                  <div className="p-4 space-y-4">
                    {[game.away, game.home].map((team, teamIndex) => {
                      const pick = `${team.abbreviation} (${
                        team.spread > 0 ? "+" : ""
                      }${team.spread})`;
                      const pickStatus = getPickStatus(index, pick);
                      const isSelected = selectedPicks[index]?.includes(
                        team.abbreviation
                      );

                      return (
                        <div
                          key={teamIndex}
                          className={`flex items-center justify-between p-3 rounded-md ${
                            !editablePicks[index]
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } transition-colors border-2 ${
                            isSelected && (isFormEditable || !hasSubmittedPicks)
                              ? editablePicks[index]
                                ? "bg-blue-900 border-blue-500"
                                : pickStatus === "correct"
                                ? "bg-green-900 border-green-500"
                                : pickStatus === "incorrect"
                                ? "bg-red-900 border-red-500"
                                : pickStatus === "push"
                                ? "bg-yellow-900 border-yellow-500"
                                : "bg-blue-900 border-blue-500"
                              : "bg-gray-700 border-gray-700 sm:hover:bg-gray-600 sm:hover:border-gray-600"
                          } focus:outline-none focus:ring-0`}
                          onClick={() =>
                            handlePickClick(
                              index,
                              teamIndex === 0 ? "away" : "home"
                            )
                          }
                        >
                          <div className="flex items-center space-x-3">
                            <img
                              src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${team.abbreviation.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=100&h=100`}
                              alt={team.abbreviation}
                              className="w-10 h-10"
                            />
                            <div className="flex flex-col">
                              <span className="font-medium text-gray-200">
                                {team.name}
                              </span>
                              <span className="text-xs text-gray-400">
                                {team.record || "N/A"}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <span
                              className={`font-semibold ${
                                team.spread > 0
                                  ? "text-red-400"
                                  : "text-green-400"
                              }`}
                            >
                              {team.spread > 0 ? "+" : ""}
                              {team.spread}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {!editablePicks[index] && (
                    <div className="bg-gray-900 text-gray-400 p-2 text-center">
                      {getGameStatus(game.startTime, index) === "LIVE" && (
                        <LiveContainer className="mb-1">
                          <LiveDot />
                          <LiveText>LIVE</LiveText>
                        </LiveContainer>
                      )}
                      <div className="text-sm">
                        {gameScores[index]?.score ||
                          (getGameStatus(game.startTime, index) === "FINAL"
                            ? "Final score not available"
                            : isFormEditable || !hasSubmittedPicks
                            ? selectedPicks[index] || "Not Submitted"
                            : "Pick hidden")}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>

            {teams.length > 0 && (
              <div className="bg-gray-800 rounded-lg p-6">
                <label
                  htmlFor="tiebreaker"
                  className="block text-gray-200 mb-2 font-semibold"
                >
                  Total Score of the Monday Game
                </label>
                <input
                  type="number"
                  id="tiebreaker"
                  name="tiebreaker"
                  value={tiebreaker}
                  placeholder="42"
                  onChange={(e) => setTiebreaker(e.target.value)}
                  className="w-full p-3 bg-gray-700 text-gray-200 rounded-md border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            )}

            {/* Add submit button here */}
            {!isEditing && (isFormEditable || !hasSubmittedPicks) && (
              <div className="flex justify-center mt-6">
                <Button
                  type="submit"
                  color="blue"
                  className="text-white font-bold py-2 px-6 rounded text-lg flex items-center"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                      Submitting...
                    </>
                  ) : (
                    <>
                      Submit Picks
                      <ChevronRight className="w-5 h-5 ml-2" />
                    </>
                  )}
                </Button>
              </div>
            )}
          </form>
        )}

        {isUploading && (
          <PicksLoader onComplete={() => setIsUploading(false)} />
        )}

        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          title={modalContent.title}
          message={modalContent.message}
          type={modalContent.type}
        />

        {isEditing && (
          <div className="fixed bottom-0 left-0 right-0 bg-gray-800 p-4 pb-3 flex justify-center z-50">
            <div className="flex flex-col space-y-2 pb-[env(safe-area-inset-bottom)]">
              <div className="flex space-x-4">
                <Button
                  type="button"
                  onClick={handleCancelEdit}
                  color="red"
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded text-sm flex items-center"
                  disabled={isSubmitting}
                >
                  Cancel
                  <XCircle className="w-4 h-4 ml-2" />
                </Button>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  color="blue"
                  className="text-white font-bold py-2 px-4 rounded text-sm flex items-center"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      Updating...
                    </>
                  ) : (
                    <>
                      Update
                      <ChevronRight className="w-4 h-4 ml-2" />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default HomePage;
