import React from "react";
import Header from "./components/Header";
import BottomNav from "./components/BottomNav";

const Layout = ({ children, username, onLogout, hideBottomNav }) => {
  return (
    <div className="App min-h-screen flex flex-col bg-gray-900">
      <Header userName={username} onLogout={onLogout} />
      <main className="flex-grow pt-20 pb-20 px-4 overflow-hidden">
        <div className="container mx-auto max-w-4xl h-full overflow-auto">
          {children}
        </div>
      </main>
      {!hideBottomNav && <BottomNav />}
    </div>
  );
};

export default Layout;
