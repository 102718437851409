import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  User,
  Award,
  Percent,
  TrendingUp,
  Loader2,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { getLatestAvailableWeek } from "./weekDates";
import { supabase } from "../supabaseClient";
import {
  getGamesForWeek,
  isPickCorrect,
  updateScores,
  hasGameFinished,
} from "../gameData";

// Clear sessionStorage on initial load if a timestamp is older than a threshold
const THRESHOLD = 1000; // 1 second
const now = new Date().getTime();
const lastVisit = sessionStorage.getItem("lastVisit");
if (!lastVisit || now - lastVisit > THRESHOLD) {
  sessionStorage.clear();
}
sessionStorage.setItem("lastVisit", now);

const StatCard = ({ icon: Icon, title, value }) => (
  <div className="bg-gray-800 rounded-lg shadow-md p-3 sm:p-4 flex items-center justify-between h-full w-full">
    <div className="rounded-full p-2 bg-gray-700 flex-shrink-0">
      <Icon size={20} className="text-blue-400" />
    </div>
    <div className="flex flex-col items-end">
      <h3 className="text-xs sm:text-sm font-semibold text-gray-400 mb-1">
        {title}
      </h3>
      <p className="text-lg sm:text-xl font-bold text-gray-100">{value}</p>
    </div>
  </div>
);

const StatsGrid = ({ userStats }) => (
  <div className="grid grid-cols-2 gap-3 sm:gap-4 mb-6 w-full pt-8">
    <StatCard
      icon={Award}
      title="Correct Picks"
      value={userStats.totalCorrectPicks}
    />
    <StatCard icon={User} title="Total Picks" value={userStats.totalPicks} />
    <StatCard
      icon={Percent}
      title="Win %"
      value={`${userStats.winPercentage}%`}
    />
    <StatCard icon={TrendingUp} title="Ranking" value={`# ${userStats.rank}`} />
  </div>
);

const ProfilePage = ({ userName }) => {
  const [userStats, setUserStats] = useState(null);
  const [pickHistory, setPickHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedWeeks, setExpandedWeeks] = useState([]);

  const calculateRanks = useCallback((usersTotalCorrectPicks) => {
    const sortedUsers = Object.entries(usersTotalCorrectPicks).sort(
      ([, a], [, b]) => b - a
    );

    let currentRank = 1;
    let previousScore = null;
    let rankIncrement = 0;

    return sortedUsers.reduce((acc, [user, score], index) => {
      if (score !== previousScore) {
        currentRank += rankIncrement;
        rankIncrement = 1;
      } else {
        rankIncrement++;
      }
      previousScore = score;
      acc[user] = currentRank;
      return acc;
    }, {});
  }, []);

  const fetchUserData = useCallback(async () => {
    const cachedData = sessionStorage.getItem(`profileData_${userName}`);
    if (cachedData) {
      const data = JSON.parse(cachedData);
      setUserStats(data.userStats);
      setPickHistory(data.pickHistory);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      // Fetch all user picks from Supabase
      const { data: allUserPicks, error: userPicksError } = await supabase
        .from("user_picks")
        .select("*");

      if (userPicksError) throw userPicksError;

      const latestWeek = getLatestAvailableWeek();

      // Update scores for all weeks
      for (let week = 1; week <= latestWeek; week++) {
        await updateScores(week);
      }

      // Calculate total correct picks for all users
      const usersTotalCorrectPicks = {};
      allUserPicks.forEach((pick) => {
        if (pick.week <= latestWeek) {
          const weekGames = getGamesForWeek(pick.week);
          const correctPicks = Object.entries(pick.picks).filter(
            ([gameIndex, teamPick]) =>
              isPickCorrect(teamPick, weekGames[gameIndex])
          ).length;

          if (!usersTotalCorrectPicks[pick.username]) {
            usersTotalCorrectPicks[pick.username] = 0;
          }
          usersTotalCorrectPicks[pick.username] += correctPicks;
        }
      });

      // Calculate ranks with ties
      const userRanks = calculateRanks(usersTotalCorrectPicks);
      const rank = userRanks[userName];

      // Calculate user's stats
      let totalCorrectPicks = 0;
      let totalFinishedPicks = 0;
      const history = [];

      allUserPicks
        .filter((pick) => pick.username === userName)
        .forEach((weekData) => {
          if (weekData.week <= latestWeek) {
            const weekGames = getGamesForWeek(weekData.week);
            const picks = Object.entries(weekData.picks);
            let weekCorrectPicks = 0;
            let weekFinishedPicks = 0;

            picks.forEach(([gameIndex, pick]) => {
              const game = weekGames[gameIndex];
              if (hasGameFinished(game)) {
                weekFinishedPicks++;
                if (isPickCorrect(pick, game)) {
                  weekCorrectPicks++;
                }
              }
            });

            totalCorrectPicks += weekCorrectPicks;
            totalFinishedPicks += weekFinishedPicks;

            history.push({
              week: weekData.week,
              correctPicks: weekCorrectPicks,
              totalPicks: picks.length,
              finishedPicks: weekFinishedPicks,
              winRate:
                weekFinishedPicks > 0
                  ? (weekCorrectPicks / weekFinishedPicks) * 100
                  : 0,
              picks: weekData.picks,
              games: weekGames,
              finalScorePrediction: weekData.tiebreaker,
            });
          }
        });

      // Sort the history array by week number in descending order
      history.sort((a, b) => b.week - a.week);

      const winPercentage =
        totalFinishedPicks > 0
          ? ((totalCorrectPicks / totalFinishedPicks) * 100).toFixed(2)
          : "0.00";

      const fetchedData = {
        userStats: {
          totalCorrectPicks,
          totalPicks: totalFinishedPicks,
          winPercentage,
          rank,
        },
        pickHistory: history,
      };

      sessionStorage.setItem(
        `profileData_${userName}`,
        JSON.stringify(fetchedData)
      );

      setUserStats(fetchedData.userStats);
      setPickHistory(fetchedData.pickHistory);
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching user data:", err);
      setError("Failed to fetch user data. Please try again later.");
      setIsLoading(false);
    }
  }, [userName, calculateRanks]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const toggleWeekExpansion = (week) => {
    setExpandedWeeks((prev) =>
      prev.includes(week) ? prev.filter((w) => w !== week) : [...prev, week]
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <Loader2 className="h-16 w-16 animate-spin text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="bg-red-900 bg-opacity-50 border border-red-700 rounded-lg p-6">
          <h2 className="text-2xl font-bold text-red-300 mb-3">Error</h2>
          <p className="text-lg text-red-100">{error}</p>
        </div>
      </div>
    );
  }

  const renderPicks = (weekData) => {
    return (
      <div className="bg-gray-800 p-3 sm:p-4 rounded-lg shadow-lg max-w-4xl mx-auto">
        <h3 className="text-lg sm:text-xl font-bold text-blue-300 mb-3 sm:mb-4 text-center">
          Week {weekData.week} Picks
        </h3>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-3">
          {Object.entries(weekData.picks).map(([gameIndex, pick], index) => {
            const gameData = weekData.games[index];
            const gameFinished = hasGameFinished(gameData);
            const isCorrect = gameFinished && isPickCorrect(pick, gameData);
            const isPush = gameFinished && gameData.winner === "PUSH";
            const isNull = !gameData.winner;
            const gameStarted = new Date(gameData.startTime) <= new Date();

            let [teamAbbr, spread] = pick.split(" ");
            // Remove parentheses from spread if they exist
            spread = spread.replace(/[()]/g, "");
            const team =
              gameData.away.abbreviation === teamAbbr
                ? gameData.away
                : gameData.home;

            return (
              <div
                key={index}
                className={`bg-gray-750 rounded-lg p-2 sm:p-3 relative ${
                  !gameStarted
                    ? "border-gray-500"
                    : isNull || !gameFinished
                    ? "border-gray-500"
                    : isPush
                    ? "border-yellow-500"
                    : isCorrect
                    ? "border-green-500"
                    : "border-red-500"
                } border-2 shadow-md`}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <img
                      src={`https://a.espncdn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${teamAbbr.toLowerCase()}.png&scale=crop&cquality=100&location=origin&w=50&h=50`}
                      alt={team.name}
                      className="w-8 h-8 sm:w-10 sm:h-10"
                    />
                    <span className="text-sm sm:text-base font-medium text-gray-200">
                      {team.name}
                    </span>
                  </div>
                  <span className="text-sm sm:text-base font-semibold text-gray-300">
                    {spread}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        {weekData.finalScorePrediction && (
          <div className="mt-4 sm:mt-5 bg-blue-800 rounded-lg p-3 sm:p-4 shadow-lg">
            <div className="flex justify-between items-center">
              <span className="text-base sm:text-lg font-semibold text-blue-200">
                Tiebreaker
              </span>
              <span className="text-xl sm:text-2xl font-bold text-blue-100">
                {weekData.finalScorePrediction}
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const PickHistory = [...pickHistory];

  return (
    <div className="min-h-screen text-gray-100 p-2 sm:p-4 md:p-6">
      <div className="w-full max-w-4xl mx-auto flex flex-col">
        <section className="mb-6">
          {userStats && <StatsGrid userStats={userStats} />}
        </section>

        <div className="flex-grow">
          <div className="bg-gray-800 rounded-lg shadow-2xl overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="bg-gray-750">
                  <tr>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Week
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Correct
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Total
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider">
                      Win Rate
                    </th>
                    <th className="px-2 py-3 sm:py-4 text-center text-xs font-medium text-gray-400 uppercase tracking-wider w-8"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-700">
                  {PickHistory.map((week, index) => (
                    <React.Fragment key={week.week}>
                      <tr
                        className={`transition-colors cursor-pointer hover:bg-gray-750 ${
                          expandedWeeks.includes(week.week) ? "bg-gray-700" : ""
                        }`}
                        onClick={() => toggleWeekExpansion(week.week)}
                      >
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center">
                          <span className="text-sm font-medium text-gray-300">
                            Week {week.week}
                          </span>
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center text-sm sm:text-base font-semibold">
                          {week.correctPicks}
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center text-sm sm:text-base font-semibold">
                          {week.finishedPicks}
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center text-sm sm:text-base font-semibold">
                          {week.finishedPicks > 0
                            ? week.winRate.toFixed(2)
                            : "0.00"}
                          %
                        </td>
                        <td className="px-2 py-3 sm:py-4 whitespace-nowrap text-center">
                          {expandedWeeks.includes(week.week) ? (
                            <ChevronUp
                              className="text-blue-400 inline-block"
                              size={18}
                            />
                          ) : (
                            <ChevronDown
                              className="text-blue-400 inline-block"
                              size={18}
                            />
                          )}
                        </td>
                      </tr>
                      {expandedWeeks.includes(week.week) && (
                        <tr>
                          <td
                            colSpan="5"
                            className="px-2 py-4 sm:py-6 bg-gray-800"
                          >
                            {renderPicks(week)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default ProfilePage;
